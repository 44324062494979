@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;500;600;700;800;900&display=swap");
/* @import 'tailwindcss/base';
@import 'tailwindcss/components'; */
@import 'tailwindcss/utilities';
:root {
  --color-primary: #ffffff;
  --color-dark: #626262;
  --color-darker: #464646;
  --color-light: #dbd8d6;
  --color-lighter: #f5f0ec;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 1px;
  background-image: linear-gradient(to right top, #fcbacc, #fcbacc, #fcbacc, #fcbacc, #fcbacc, #fcbacc, #fcbacc, #fcbacc, #fcbacc, #fcbacc, #fcbacc, #fcbacc);
}

.wrapper {
  margin: 0 5vw;
}

section {
  padding: 10vw 0;
}

.min-h-100vh {
  min-height: 100vh;
}

.section-title {
  font-family: "Syncopate", sans-serif;
  font-size: 1.5rem;
  text-transform: lowercase;
  padding-bottom: 3vw;
  color: whitesmoke;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem 0;
  font-family: "Syncopate", sans-serif;
  font-size: 1.5rem;
  text-transform: lowercase;
}

.links,
.favourite-link {
  font-weight: 600;
} 

.links a,
.favourite-link a {
  color: var(--color-dark);
  position: relative;
}

.links a::before,
.favourite-link a::before {
  content: "";
  width: 0%;
  height: 0.2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-primary);
  transition: 0.5s;
}

.links a:hover::before,
.favourite-link a:hover::before {
  width: 120%;
}

.logo a {
  color: white;
  text-transform: uppercase;
}

.hero {
  font-family: "Bai Jamjuree", sans-serif;
  text-transform: uppercase;
  font-size: 6vw;
  text-align: center;
  line-height: 1.2;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ethereal,
.canvas {
  position: relative;
}

.ethereal span,
.canvas span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: var(--color-lighter);
}

.photos {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

.photo {
  width: 120%;
  height: 120%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.photo.one {
  grid-column: 1;
  grid-row: 2;
  
}

.photo.two {
  grid-column: 4;
  grid-row: 5;
  width: 150%;
  height: 150%;
}

.photo.three {
  grid-column: 2;
  grid-row: 5;
}

.photo.four {
  grid-column: 6;
  grid-row: 3 ;
}

.photo.five {
  grid-column: 6;
  grid-row: 1;
  width: 170%;

}

.featured-wrapper {
  display: grid;
  grid-template-columns: 30% auto;
  align-items: center;
  gap: 10rem;
}

.featured-wrapper span {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 5px;
}

.featured-wrapper img {
  width: 100%;
  display: block;
}

.featured-left {
  display: grid;
  gap: 1rem;
  position: relative;
}

.left-shutter {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fcbacc;
}

.featured-right {
  display: grid;
  gap: 1rem;
  position: relative;
}

.right-shutter {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fcbacc;
}

.about p {
  font-size:20px;
  line-height: 1.5;
  margin-bottom: 25px;
  color: white;
}



.gallery-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 10vw;
  padding: 10vw;
  background-color:#ff6f9d;
}

.gallery-item {
  position: relative;
  width: 50%;
}

.gallery-item-title {
  position: absolute;
  top: 10%;
  left: -50%;
  font-family: "Bai Jamjuree", sans-serif;
  text-transform: uppercase;
  color: var(--color-lighter);
  font-size: 8vw;
  line-height: 1.2;
  mix-blend-mode: color-dodge;
  z-index: 1;
}

.gallery-item-category {
  position: absolute;
  bottom: -5%;
  left: 0;
  text-transform: uppercase;
  color: var(--color-lighter);
  letter-spacing: 10px;
  z-index: 1;
}

.gallery-item-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.not-found {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: flex-start;
  column-gap: 5vw;
  row-gap: 1vw;
  padding: 5vw 0;
}

.headline-1,
.headline-2 {
  font-family: "Bai Jamjuree", sans-serif;
  font-size: 8vw;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
}

.img-1,
.img-2 {
  width: 20vw;
  height: 20vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.img-1 img,
.img-2 img {
  display: block;
  width: 100%;
}

.img-2 {
  justify-self: self-end;
}

.blogs {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.blog {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.blog-img {
  width: 40rem;
  height: 40rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 3px;
}

.blog-img img {
  width: 100%;
  display: block;
}

.blog-texts {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: flex-start;
  grid-column: 2/-1;
}

.blog-publisher {
  font-size: 1.5rem;
}

.blog-publisher .author,
.blog-publisher .date {
  color: var(--color-primary);
}

.blog-btn {
  font-family: inherit;
  font-size: inherit;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-lighter);
  padding: 1rem 2rem;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 0.2rem;
}

.blog-btn:hover {
  background-color: var(--color-darker);
}

.footer {
  text-align: center;
}

.footer h1 {
  font-family: "Bodoni Moda", serif;
  font-size: 10vw;
  text-transform: lowercase;
  color: var(--color-primary);
  z-index: -1;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 50%;
  }

  .wrapper {
    margin: 0 2.5vw;
  }

  section {
    padding: zvw 0;
  }

  .section-title {
    padding-bottom: 2.5vw;
  }

  .navbar {
    padding: 1rem 0;
    flex-direction: column;
  }

  .logo {
    order: -1;
  }

  .featured-wrapper {
    grid-template-columns: 1fr;
    gap: 5rem;
  }

  .blog {
    grid-template-columns: 1fr;
  }

  .blog-texts {
    grid-column: auto;
  }
}

